import type {
  ActivitiesStatsType,
  Activity,
  StudentActivityStats,
  TeacherUsageReport,
} from '../types'

import { fetchHelper } from '.'

type ActivitiesType = {
  message?: string
  activities: Activity[]
}
/**
 * Posts activities.
 * @param activities - The activities to be posted.
 * @return A promise that resolves to the posted activities.
 */
async function postActivities(
  activities: ActivitiesType,
  activityDate?: string
): Promise<ActivitiesType> {
  return fetchHelper(
    `activity?activityDate=${activityDate}`,
    'POST',
    activities
  ) as Promise<ActivitiesType>
}

/**
 * Retrieves activities.
 * @return A promise that resolves to the retrieved activities.
 */
async function getActivities(filters: { [key: string]: string } = {}): Promise<ActivitiesType> {
  const params: URLSearchParams = new URLSearchParams(filters)
  const path = `activity/?${params.toString()}`
  return fetchHelper(path, 'GET', null) as Promise<ActivitiesType>
}

type ClassStats = {
  [key: string]: number
}

/**
 * Retrieves the statistics for teacher classes page using internal period ID.
 * @param internalPeriodId The internal ID of the period.
 * @param filters The filters to apply to the request.
 * @return A promise that resolves to an object of type ClassStats.
 */
async function getPeriodStats(
  internalPeriodId: string,
  filters: { [key: string]: string } = {}
): Promise<ClassStats> {
  const params: URLSearchParams = new URLSearchParams(filters)
  const path = `activity/class-stats/${internalPeriodId}?${params.toString()}`
  return fetchHelper(path, 'GET', null) as Promise<ClassStats>
}

/**
 * Delete activity
 * @return A promise that resolves to the deleted activity.
 */
async function deleteActivityById(activityId: string): Promise<ActivitiesType> {
  const path = `activity/${activityId}`
  return fetchHelper(path, 'DELETE', null) as Promise<ActivitiesType>
}

/**
 * Update activity
 * @return A promise that resolves to the updated activity.
 */
async function updateActivityById(
  activityId: string,
  activityType: string,
  comment: string,
  points: number
): Promise<ActivitiesType> {
  const path = `activity/${activityId}`
  return fetchHelper(path, 'PUT', {
    activityType,
    comment,
    points,
  }) as Promise<ActivitiesType>
}

/**
 * Retrieves activities for teacher class report.
 * @param filters The filters to apply to the request.
 * @param filters.studentIds The student IDs to filter by.
 * @param filters.internalPeriodIds The internal period IDs to filter by.
 * @param filters.startDate The start date to filter by.
 * @param filters.endDate The end date to filter by.
 * @return A promise that resolves to the retrieved activities.
 */
async function getClassReport(filters: {
  studentIds: string[]
  internalPeriodIds: string[]
  startDate: string
  endDate: string
}): Promise<StudentActivityStats[]> {
  const path = `activity/class-report`
  return fetchHelper(path, 'POST', filters) as Promise<StudentActivityStats[]>
}

/**
 * Retrieves the statistics for teacher class page using internal period ID.
 * @param internalPeriodId The internal ID of the period.
 * @return A promise that resolves to an object of type ActivitiesStatsType.
 */
async function getDetailedPeriodStats(
  internalPeriodId: string,
  date: string = ''
): Promise<ActivitiesStatsType> {
  const params: URLSearchParams = new URLSearchParams({ date })
  return fetchHelper(
    `activity/class-stats-details/${internalPeriodId}?${params.toString()}`,
    'GET',
    null
  ) as Promise<ActivitiesStatsType>
}

/**
 * Retrieves activities for staff teacher reports.
 * @return A promise that resolves to the retrieved activities.
 */
async function getTeacherUsageReport(
  filters: { [key: string]: string } = {}
): Promise<TeacherUsageReport[]> {
  const params: URLSearchParams = new URLSearchParams(filters)
  const path = `activity/teacher-report/?${params.toString()}`
  return fetchHelper(path, 'GET', null) as Promise<TeacherUsageReport[]>
}

/**
 * Retrieves activities for staff student reports.
 * @return A promise that resolves to the retrieved activities.
 */
async function getSchoolStudentReportActivities(filters: {
  [key: string]: string
  gradeLevel: string
}): Promise<StudentActivityStats[]> {
  const params: URLSearchParams = new URLSearchParams(filters)
  const path = `activity/student-report/?${params.toString()}`
  return fetchHelper(path, 'GET', null) as Promise<StudentActivityStats[]>
}

export {
  postActivities,
  getActivities,
  getDetailedPeriodStats,
  getClassReport,
  deleteActivityById,
  updateActivityById,
  getTeacherUsageReport,
  getPeriodStats,
  getSchoolStudentReportActivities,
}
