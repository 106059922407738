import { useAuthState } from '../../context/AuthContext'
import { logout } from '../../utility'
import { APP_IMAGE_URL_PREFIX } from '../../utility/constants'

import RouteLink from './RouteLink'
import StaffHeader from './StaffHeader'
import StudentHeader from './StudentHeader'
import './style.css'
import { Box } from '@mui/material'
import { useState } from 'react'
import { LogoutExit } from 'react-basicons'

/**
 * Component to render the header based on the user type
 */
function Header() {
  const [isSlimHeader, setIsSlimHeader] = useState(true)
  const {
    isStudent,
    isTeacher,
    isSchoolAdmin,
    isStaff,
    isStoreManager,
    isGiveAll,
    isCustomizeStore,
    hasTeacherUsageReports,
    hasSchoolStudentReports,
    user,
    myPeriods,
  } = useAuthState()

  const lastNames = user?.lastName?.replace(/\s+/g, ' ').split(' ') ?? []
  const lastName = lastNames[lastNames.length - 1]

  const getClassNames = (...args: string[]) => {
    let classNames = args.join(' ')
    if (isSlimHeader) {
      classNames = classNames + ' sm'
    }
    return classNames
  }

  const getUserRole = () => {
    if (isSchoolAdmin) return 'School Admin'.toUpperCase()
    if (isStaff) return 'Staff'.toUpperCase()
    if (isTeacher) return 'Teacher'.toUpperCase()
    if (isStudent) return 'Student'.toUpperCase()
    return ''
  }

  return (
    <div className={getClassNames('header-container', 'hideOnPrint')}>
      <img
        src={
          isSlimHeader
            ? `${APP_IMAGE_URL_PREFIX}/SlimHeaderIconOpen.svg`
            : `${APP_IMAGE_URL_PREFIX}/SlimHeaderIconClose.svg`
        }
        alt=">"
        id="slimHeaderIcon"
        className={getClassNames('slim-header-icon', 'hide-small')}
        onClick={() => setIsSlimHeader(!isSlimHeader)}
        tabIndex={1}
        onKeyDown={(e: React.KeyboardEvent) => {
          // If the user presses enter, we want to trigger the onClick event
          if (e.key === 'Enter') setIsSlimHeader(!isSlimHeader)
        }}
      />
      <Box
        display="flex"
        className={getClassNames('header-logo-container')}
        sx={{
          borderBottom: '2px solid #F6F6F6',
        }}
      >
        <img src="/logo.png" height={60} alt="logo" className="header-logo" />
        <div className={getClassNames(isSlimHeader ? 'hide' : 'hide-small')}>
          <p className="user-role">{getUserRole()}</p>
          <p className="user-fullname">
            {!isStudent ? `${user?.firstName[0]}. ${lastName}` : `${user?.firstName}`}
          </p>
          <p className="user-fullname">{!isStudent ? `` : lastName}</p>
        </div>
      </Box>

      <div className={getClassNames('header-routes')}>
        {isStudent && <StudentHeader isSlim={isSlimHeader} />}
        {!isStudent && (
          <StaffHeader
            isSlim={isSlimHeader}
            isSchoolAdmin={isSchoolAdmin}
            isTeacher={isTeacher}
            isStoreManager={isStoreManager}
            isGiveAll={isGiveAll}
            isCustomizeStore={isCustomizeStore}
            hasTeacherUsageReports={hasTeacherUsageReports}
            hasSchoolStudentReports={hasSchoolStudentReports}
            periods={myPeriods}
          />
        )}
      </div>
      <div
        className={getClassNames('header-routes')}
        style={{
          marginTop: 'auto',
          paddingBottom: '10px',
        }}
      >
        <RouteLink
          isSlim={isSlimHeader}
          onClick={logout}
          link="/login"
          name="Logout"
          icon={<LogoutExit size={22} />}
        />
      </div>
    </div>
  )
}
export default Header
