import type { CartItem, Order, OrderStats } from '../types'

import { fetchHelper } from '.'

/**
 * Get orders for logged in student
 * @return {Promise<Order[]>}
 */
async function getStudentOrders(filters: { [key: string]: string } = {}): Promise<Order[]> {
  const params: URLSearchParams = new URLSearchParams(filters)
  const path = `order/student?${params.toString()}`
  return fetchHelper(path, 'GET', null) as Promise<Order[]>
}

/**
 * Get orders for logged in teacher to manage
 * @return {Promise<Order[]>}
 */
async function getTeacherOrders(filters: { [key: string]: string } = {}): Promise<Order[]> {
  const params: URLSearchParams = new URLSearchParams(filters)
  const path = `order/teacher/?${params.toString()}`

  return fetchHelper(path, 'GET', null) as Promise<Order[]>
}

/**
 * Get orders for logged in store manager to manage
 * @return {Promise<Order[]>}
 */
async function getStoreManagerOrders(filters: { [key: string]: string } = {}): Promise<Order[]> {
  const params: URLSearchParams = new URLSearchParams(filters)
  const path = `order/store-manager/?${params.toString()}`

  return fetchHelper(path, 'GET', null) as Promise<Order[]>
}

type StoreManagerMeta = {
  users: string[]
  estimatedDelivery: string[]
  dateUserMap: { [key: string]: string[] }
}

/**
 * Get student ids for orders with given status
 */
async function getStoreManagerMeta(
  filters: { [key: string]: string } = {}
): Promise<StoreManagerMeta> {
  const params: URLSearchParams = new URLSearchParams(filters)
  const path = `order/store-manager/meta?${params.toString()}`

  return fetchHelper(path, 'GET', null) as Promise<StoreManagerMeta>
}

/**
 * Save order for logged in user
 * @return {Promise<Order>}
 */
async function saveOrder(orderItems: CartItem[], studentId?: string): Promise<Order> {
  return fetchHelper('order', 'POST', { orderItems, studentId }) as Promise<Order>
}

/**
 * Get order item name count by status
 * @return {Promise<OrderStats>}
 */
async function getItemIdOrderCountByStatus(status: string): Promise<OrderStats> {
  return fetchHelper(`order/stats/${status}`, 'GET', null) as Promise<OrderStats>
}

/**
 * Update order item by logged in user
 * @return {Promise<Order>}
 */
async function updateOrderItem(
  action: 'cancel' | 'refund' | 'redeem' | 'undo',
  orderId: string,
  itemId: string,
  classFilter?: string,
  comment?: string
): Promise<Order> {
  return fetchHelper(
    `order/${orderId}/item/${itemId}/${action}?classFilter=${classFilter}&comment=${comment}`,
    'PUT',
    null
  ) as Promise<Order>
}

export {
  updateOrderItem,
  saveOrder,
  getTeacherOrders,
  getStudentOrders,
  getStoreManagerOrders,
  getStoreManagerMeta,
  getItemIdOrderCountByStatus,
}
