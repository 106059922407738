import App from './App'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

const theme = createTheme({
  palette: {
    primary: {
      main: '#6366f1',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Poppins, sans-serif',
    },
  },
})

const container = document.getElementById('app') as HTMLElement
const root = createRoot(container)

if (process.env.REACT_APP_ENABLE_SENTRY === 'Y') {
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    dsn: 'https://076dbd6a5c80701945f932ed7563b622@o4506599232634880.ingest.sentry.io/4506606939930624',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [
      /^Unauthorized$/,
      /^TypeError: Load failed$/,
      /^Load failed$/,
      /^TypeError: Failed to fetch$/,
      'Keep working! You need to earn',
      'Error getting Clever token. Try again later.',
      'You cannot cancel this item because the cancellation date has passed.',
      'Invalid username/password',
      'User not registered in our system. Please contact your school admin.',
      'Unable to send bonus/flag. You may start giving bonuses and flags on',
      'is out of stock',
      'The store is closed. It will reopen on',
      '"Name" can be 32 characters at most. You may use the “Description” box to provide additional details about this item.',
      'School is on holiday',
      'Not allowed on holidays',
      /^Requested quantity/,
      'Comment must be at most 150 characters',
      'Not allowed on weekends',
      'Clever user ID mismatch',
      'Please select a student.',
      /^The student needs to earn (\d+) more BiziBucks to make this purchase./,
    ],
  })
}

root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>
)
