import { getStatus } from '../../api'
import { useAuthState } from '../../context/AuthContext'
import Header from '../Header'
import Loading from '../LoadingSpinner'

import Box from '@mui/material/Box'
import { captureMessage } from '@sentry/react'
import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

interface AuthenticatedRouteProps {
  children: React.ReactNode
}

/**
 * Component to protect routes that require authentication
 * @param children Child components to render if authenticated
 */
export const AuthenticatedRoute = ({ children }: AuthenticatedRouteProps) => {
  const [release, setRelease] = useState<string | null>(localStorage.getItem('release'))
  const {
    isAuthenticated,
    loading,
    isTeacher,
    isSchoolAdmin,
    isStudent,
    isGiveAll,
    isStoreManager,
    hasTeacherUsageReports,
    hasSchoolStudentReports,
  } = useAuthState()

  // Check for new release every hour
  useEffect(() => {
    checkForUpdate()
    const id = setInterval(() => {
      checkForUpdate()
    }, 1000 * 60 * 60 * 1) // 1 hour
    return () => clearInterval(id)
  }, [])

  // Fetch the status to check for new release
  const checkForUpdate = async () => {
    const statusResponse = await getStatus()
    if (statusResponse?.release) {
      setRelease(statusResponse.release)
    }
  }

  // If a new release is detected, reload the page
  useEffect(() => {
    if (!release) {
      return
    }

    const currentRelease = localStorage.getItem('release')
    // Update the release in local storage
    localStorage.setItem('release', release)

    if (currentRelease && currentRelease !== release) {
      captureMessage('New release detected, reloading page')
      setTimeout(() => {
        window.location.reload()
      }, 5000)
    }
  }, [release])

  const isRouteAllowed = () => {
    const currentPath = window.location.pathname
    if (currentPath === '/') {
      return true
    }

    const OPEN_ROUTES = [
      '/login',
      '/login/bizi',
      '/status',
      '/how-to-earn',
      '/contact',
      '/profile',
      '/calendar',
      '/store',
      '/pay-period-breakdown',
    ]

    const TEACHER_ALLOWED_ROUTES = [...OPEN_ROUTES, '/orders', '/classes', '/class', '/rosters']
    const STUDENT_ALLOWED_ROUTES = [...OPEN_ROUTES, '/orders', '/dashboard', '/activity']
    const ADMIN_ALLOWED_ROUTES = [...OPEN_ROUTES, '/bonuses-flags']
    const GIVE_ALL_ALLOWED_ROUTES = [...OPEN_ROUTES, '/bonuses-flags']
    const STORE_MANAGER_ALLOWED_ROUTES = [...OPEN_ROUTES, '/orders']
    const REPORTS_ALLOWED_ROUTES = [...OPEN_ROUTES, '/reports']

    // If teacher, only allow teacher routes
    if (
      isTeacher &&
      TEACHER_ALLOWED_ROUTES.filter((route) => currentPath.startsWith(route)).length > 0
    ) {
      return true
    }
    if (
      isStudent &&
      STUDENT_ALLOWED_ROUTES.filter((route) => currentPath.startsWith(route)).length > 0
    ) {
      return true
    }
    if (
      isSchoolAdmin &&
      ADMIN_ALLOWED_ROUTES.filter((route) => currentPath.startsWith(route)).length > 0
    ) {
      return true
    }
    if (
      isGiveAll &&
      GIVE_ALL_ALLOWED_ROUTES.filter((route) => currentPath.startsWith(route)).length > 0
    ) {
      return true
    }
    if (
      isStoreManager &&
      STORE_MANAGER_ALLOWED_ROUTES.filter((route) => currentPath.startsWith(route)).length > 0
    ) {
      return true
    }
    if (
      (hasTeacherUsageReports || hasSchoolStudentReports || isTeacher) &&
      REPORTS_ALLOWED_ROUTES.filter((route) => currentPath.startsWith(route)).length > 0
    ) {
      return true
    }
    return false
  }

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        padding={2}
        className="app-page authenticated-route-splash"
      >
        <Loading />
      </Box>
    )
  }

  if (isAuthenticated && isRouteAllowed()) {
    return (
      <Box
        display="flex"
        sx={{
          overflow: 'hidden',
          height: '100vh',
        }}
      >
        <Header />
        {children}
      </Box>
    )
  }

  if (isAuthenticated && !isRouteAllowed()) {
    return <Navigate to="/" />
  }

  return <Navigate to="/login" />
}
