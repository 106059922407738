import { getSchoolPeriods, getUserBalanceStats, me } from '../api'
import { BalanceStats, Period, type User } from '../types'
import { captureSentryException } from '../utility'

import { useState, useEffect, useContext, createContext } from 'react'

type UserContextType = {
  user: User | null
  loading: boolean
  updateUser: (user: User) => void
  updateBalance: () => Promise<void>
  balanceStats: BalanceStats
  myPeriods: Array<Period>
  schoolPeriods: Array<Period>
}

const defaultBalanceStats: BalanceStats = {
  earnedYesterday: 0,
  balance: 0,
  totalEarned: 0,
  totalSpent: 0,
}
/**
 * Context to store the logged in user data.
 */
export const AuthContext = createContext<UserContextType>({
  user: null,
  loading: true,
  updateUser: (user: User) => {
    return user
  },
  updateBalance: async () => {},
  myPeriods: [],
  schoolPeriods: [],
  balanceStats: defaultBalanceStats,
})

export const AuthContextProvider = (props: object) => {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const [balanceStats, setBalanceStats] = useState<BalanceStats>(defaultBalanceStats)
  const [myPeriods, setMyPeriods] = useState<Array<Period>>([])
  const [schoolPeriods, setSchoolPeriods] = useState<Array<Period>>([])

  const updateUser = (user: User) => {
    setUser(user)
  }

  const updateBalance = async () => {
    const balance = await getUserBalanceStats()
    if (balance.message) {
      return
    }
    setBalanceStats({ ...balance })
  }

  const getLoggedInUser = async () => {
    setLoading(true)
    try {
      const loggedInUser = await me()
      if (loggedInUser.message) {
        setLoading(false)
        setUser(null)
        return
      }

      if (!loggedInUser?.roles.includes('STUDENT')) {
        const periodsResponse = await getSchoolPeriods()

        if (periodsResponse && Array.isArray(periodsResponse)) {
          const updatedMyPeriods = periodsResponse
            .filter(
              (period) =>
                period.externalTeacherId === loggedInUser.externalId ||
                period.coTeachers.includes(loggedInUser.externalId)
            )
            .filter((period) => period.students.length > 0)
            .sort((a, b) => {
              // Prefer the teacher's period
              if (loggedInUser._id === a.teacher && loggedInUser._id !== b.teacher) {
                return -1
              } else if (loggedInUser._id !== a.teacher && loggedInUser._id === b.teacher) {
                return 1
              }

              const byPeriodNumber = a.periodNumber - b.periodNumber
              const bySectionNumber = getSectionNumber(a).localeCompare(getSectionNumber(b))
              return byPeriodNumber || bySectionNumber
            })
          setMyPeriods(updatedMyPeriods)
          setSchoolPeriods(
            periodsResponse
              .filter((period) => period.students.length > 0)
              .sort((a, b) => a.periodNumber - b.periodNumber)
          )
        }
      }

      if (loggedInUser?.roles.includes('STUDENT')) {
        const balance = await getUserBalanceStats()
        if (balance.message) {
          return
        }
        setBalanceStats(balance)
      }
      setUser(loggedInUser)
      // eslint-disable-next-line
    } catch (e: any) {
      setLoading(false)
      setUser(null)
      captureSentryException(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    setUser(null)
    getLoggedInUser()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        updateUser,
        updateBalance,
        balanceStats,
        myPeriods: myPeriods,
        schoolPeriods: schoolPeriods,
      }}
      {...props}
    />
  )
}

/**
 * Get the section number of the period if it exists.
 * If it doesn't exist, return an empty string.
 * @param period Period
 * @return string Section number
 */
const getSectionNumber = (period: Period) => {
  const sectionNumbers = period.sectionNumbers
  if (!sectionNumbers || sectionNumbers.length === 0) {
    return ''
  }
  return sectionNumbers[0]
}

/**
 * Custom hook to get the logged in user data
 */
export const useAuthState = (): {
  user: User | null
  isAuthenticated: boolean
  loading: boolean
  updateUser: (user: User) => void
  updateBalance: () => Promise<void>
  isStudent: boolean
  isTeacher: boolean
  isStoreManager: boolean
  isSchoolAdmin: boolean
  isStaff: boolean
  balanceStats: BalanceStats
  myPeriods: Array<Period>
  schoolPeriods: Array<Period>
  isPickupModel: boolean
  isGiveAll: boolean
  isCustomizeStore: boolean
  hasTeacherUsageReports: boolean
  hasSchoolStudentReports: boolean
  hasSchoolDashboard: boolean
  isElementary: boolean
} => {
  const auth = useContext(AuthContext)
  return {
    loading: Boolean(auth.loading),
    user: auth.user,
    balanceStats: auth.balanceStats,
    myPeriods: auth.myPeriods,
    schoolPeriods: auth.schoolPeriods,
    isAuthenticated: !!auth.user?._id,
    isTeacher: auth.user?.roles.includes('TEACHER') ?? false,
    isStudent: auth.user?.roles.includes('STUDENT') ?? false,
    isSchoolAdmin: auth.user?.roles.includes('SCHOOL_ADMIN') ?? false,
    isStoreManager: auth.user?.roles.includes('STORE_MANAGER') ?? false,
    isStaff: auth.user?.roles.includes('STAFF') ?? false,
    isGiveAll: auth.user?.roles.includes('GIVE_ALL') ?? false,
    isCustomizeStore: auth.user?.roles.includes('CUSTOMIZE_STORE') ?? false,
    hasTeacherUsageReports: auth.user?.roles.includes('TEACHER_USAGE_REPORTS') ?? false,
    hasSchoolStudentReports: auth.user?.roles.includes('SCHOOL_STUDENT_REPORTS') ?? false,
    hasSchoolDashboard: auth.user?.roles.includes('SCHOOL_DASHBOARD') ?? false,
    isPickupModel: auth.user?.school.storeModel === 'Pickup',
    isElementary: auth.user?.school.schoolType.includes('Elementary') ?? false,
    updateUser: auth.updateUser,
    updateBalance: auth.updateBalance,
  }
}
